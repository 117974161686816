<div class="container text-center padding10x0" #form>
    <div class="container login-bg">
        <img class="reesink-logo" src={{bannerLogo}}>
    </div>
    <h3 class="marginTop15 marginBottom15">{{'api.request_new_password' | translate}}</h3>

    <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
            <div class="col-sm-12 reset-background">
                <i class="fa fa-user fontSize150" aria-hidden="true"></i>
                <form method="post">
                    <div class="login-form">
                        <div class="form-group marginBottom0">

              <div class="inputs-group">
                <input
                  (input)="onPwType('password' , $event)"
                  name="password"
                  [type]="passwordVisible.password ? 'text' : 'password'"
                  title="{{'api.type_new_password' | translate}}"
                  [value]="credentials.password"
                  class="form-control login-field marginBottom15" id="password"
                  placeholder="{{'api.type_new_password' | translate}}"
                  required="required"
                >
                <span (click)="togglePasswordVisibility('password')">
                  <i class="fas"
                     [ngClass]="{ 'fa-eye': passwordVisible.password, 'fa-eye-slash': !passwordVisible.password }"></i>
                </span>
              </div>
              <div class="inputs-group">
                <input
                  (input)="checkSamePw($event)"
                  [ngModel]="credentials.repassword"
                  [type]="passwordVisible.password_confirmation ? 'text' : 'password'"
                  name="repassword"
                  title="{{'api.retype_new_password' | translate}}"
                                   class="form-control login-field" id="repassword"
                  placeholder="{{'api.retype_new_password' | translate}}"
                  required="required"
                />

                <span (click)="togglePasswordVisibility('password_confirmation')">
                  <i class="fas"
                     [ngClass]="{ 'fa-eye': passwordVisible.password_confirmation, 'fa-eye-slash': !passwordVisible.password_confirmation }"></i>
                </span>

              </div>

                        </div>
            <!-- if password length > 1 -->
            <div
              *ngIf="credentials.password.length > 0"
            >
              <p class="text-start" [class.valid]="isvalid.length">
                <i class="fas" [ngClass]="{ 'fa-check': isvalid.length, 'fa-xmark': !isvalid.length }"></i>
                <!-- At least 8 characters  -->
                 {{'api.at_least_8_characters' | translate}}
              </p>
              <p class="text-start" [class.valid]="isvalid.lower">
                <i class="fas" [ngClass]="{ 'fa-check': isvalid.lower, 'fa-xmark': !isvalid.lower }"></i>
                <!-- At least one lowercase letter -->
                {{'api.at_least_one_lowercase_letter' | translate}}
              </p>
              <p class="text-start" [class.valid]="isvalid.upper">
                <i class="fas" [ngClass]="{ 'fa-check': isvalid.upper, 'fa-xmark': !isvalid.upper }"></i>
                <!-- At least one uppercase letter -->
                {{'api.at_least_one_uppercase_letter' | translate}}
              </p>
              <p class="text-start" [class.valid]="isvalid.digit">
                <i class="fas" [ngClass]="{ 'fa-check': isvalid.digit, 'fa-xmark': !isvalid.digit }"></i>
                <!-- At least one digit -->
                {{'api.at_least_one_digit' | translate}}
              </p>
              <p class="text-start" [class.valid]="isvalid.special">
                <i class="fas" [ngClass]="{ 'fa-check': isvalid.special, 'fa-xmark': !isvalid.special }"></i>
                <!-- At least one special character -->
                {{'api.at_least_one_special_character' | translate}}
              </p>
              <p class="text-start" [class.valid]="isvalid.confirmPassword">
                <i class="fas"
                   [ngClass]="{ 'fa-check': isvalid.confirmPassword, 'fa-xmark': !isvalid.confirmPassword }"></i>
                <!-- Passwords match  -->
                {{'api.passwords_match' | translate}}
              </p>
            </div>

            <p><input
              [disabled]="!isvalid.confirmPassword || credentials.password.length < 1"
              (click)="resetPassword()" type="submit" name="password_reset_submit" title=""
              value="{{'api.confirm_new_password' | translate}}"
                                  id="submit-button" class="btn btn-primary btn-lg btn-block reset-btn"></p>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-sm-4"></div>
    </div>
</div>

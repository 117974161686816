import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../../shared/services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {GeneralService} from '../../../shared/services/general.service';
import {GroupSelectionService} from '../../../internal-platform-module/services/group-selection.service';
import {ErrorEventService} from '../../../shared/services/error-event.service';
import {LoadingEventService} from '../../../shared/services/loading-event.service';
import {GeneralDetail} from '../../../shared/helper-classes/general-detail';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-reset-password',
    templateUrl: 'reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent extends GeneralDetail implements OnInit, OnDestroy {

    public credentials: any = {
        token: '',
        password: '',
        password_confirmation: '',
    };

    isvalid = {
        length : false,
        lower : false,
        upper : false,
        digit : false,
        special : false,
        confirmPassword : false
    }

    passwordVisible = {
        password: false,
        password_confirmation: false
    }

    private sub: any;
    public bannerLogo = environment.toro_logo;
    /**
     * @param {AuthService} authService
     * @param errorEventService
     * @param loadingEventService
     * @param generalService
     * @param {ActivatedRoute} route
     * @param {Router} router
     * @param renderer
     * @param groupSelectionService
     */
    constructor(
        private authService: AuthService,
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        protected generalService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected groupSelectionService: GroupSelectionService) {
        super(generalService, router, route, errorEventService, loadingEventService, renderer);
        super.parentInitialize();
    }

    /**
     * makes the reset password API call
     * navigates to the the path after login for different interfaces
     */
    resetPassword() {
        this.authService.sendResetPassword(this.credentials).subscribe(
            () => {
                this.router.navigate(['/login']);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /**
     * interprets the parameters
     * sets token, sets credential token and redirect url
     */
    ngOnInit() {
        this.sub = this.route.params.subscribe( params => {
            this.credentials.token = params['token'];
        });
    }

    /**
    * unsubscribes route loading event
    */
    ngOnDestroy() {
        super.ngOnDestroy();
        this.sub.unsubscribe();
    }

    // TypeScript: Password validation logic
    onPwType(key: string, event: any) {
        const password = event.target.value;
        // Regular expression for the password validation
        const regexLength = /.{8,}/;                          // At least 8 characters
        const regexLower = /[a-z]/;                            // At least one lowercase letter
        const regexUpper = /[A-Z]/;                            // At least one uppercase letter
        const regexDigit = /\d/;                               // At least one digit
        const regexSpecial = /[@$!%*?&]/;                       // At least one special character

        // Check each condition
        this.isvalid = {
            length : regexLength.test(password),
            lower : regexLower.test(password),
            upper : regexUpper.test(password),
            digit : regexDigit.test(password),
            special : regexSpecial.test(password),
            confirmPassword : false
        }

        this.credentials[key] = password;
    }

    checkSamePw(event) {
        const confirmPassword = event.target.value;
        if (this.credentials.password === confirmPassword) {
            this.isvalid.confirmPassword = true;
        } else {
            this.isvalid.confirmPassword = false;
        }
        this.credentials.password_confirmation = confirmPassword;
    }

    togglePasswordVisibility(key: string) {
        this.passwordVisible[key] = !this.passwordVisible[key];
    }


}
